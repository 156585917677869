.HeroText {
  margin-left: 50px;
  color: white;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
}

.HeroText h1 {
  margin: 5px 0;
  font-size: 3em;
}

.HeroText .homeText {
  border: 0;
}

.HeroText p {
  font-size: 1.2em;
}

@media all and (max-width: 1199px) {
  .HeroText h1 {
    font-size: 2.7em;
  }
}

@media all and (max-width: 991px) { 
  .HeroText h1 {
    font-size: 2.4em;
    width: 500px
  }
} 

@media all and (max-width: 575px) {
  .HeroText h1 {
    font-size: 1.9em;
    width: 85%;
  }
}