.NavButton{
  padding-left: 20px;
  display: flex;
  align-items: center;
  height: 80px;
  border: 0;
}

.NavButton:hover {
  background-color: #333;
  cursor: pointer;
}

.NavButton:focus,
.NavButton:target{
  outline: none;
  border: 0;
  background-color: crimson;
}

.NavButton ion-icon {
  font-size: 1.3em;
  margin-right: 10px;
  color: #ddd;
  transition: color 0.2s;
}

.NavButton .item-name {
  color: #ddd;
}

.active, .active .NavButton:hover {
  background-color: crimson;
}

@media all and (max-width: 991px) { 
  .NavButton{
    height: 70px;
  }
} 

@media all and (max-width: 768px) {
  .NavButton{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    padding-left: 0;
    justify-content: center;
  }

  .NavButton ion-icon {
    font-size: 1.5em;
    margin-right: 0;
    margin-bottom: 8px;
  }
} 
