.Particles {
  position: absolute;
  top: 0;
  overflow: hidden;
  width: calc(100vw - 200px);
  height: 100%;
  opacity: 0.8;
}

@media all and (max-width: 768px) {
  .Particles {
    height: calc(100vh - 80px);
    width: 100vw;
    opacity: 0.5;
  }
}

@media all and (max-width: 575px) {
  .Particles {
    opacity: 0.25;
  }
}