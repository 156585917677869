.Projects.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}

.project-btn {
  width: 150px;
  height: 180px;
  color: #ddd;
  font-size: 1.5em;
  background-color: transparent;
  border: 2px solid #7aeec7;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color ease 0.3s, transform ease 0.3s;
}

.Projects .second {
  margin-top: 30px;
  margin-right: 30px;
}

.project-btn:hover {
  border-color: crimson;
  transform: rotate(-7deg);
}

.Projects .first {
  margin-bottom: 30px;
}

@media all and (max-width: 575px) {
  .project-btn {
    width: 120px;
    height: 140px;
    font-size: 1.3em;
  }
}