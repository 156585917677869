.NavigationBar {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 150px;
  justify-content: center;
  height: 100vh;
  background-color: #181818;
  overflow: hidden;
}

.NavigationBar a:focus{
  background-color: #dc143c;
}

@media all and (max-width: 991px) { 
  .NavigationBar {
    width: 140px;
    font-size: 0.9em;
  }
}

@media all and (max-width: 768px) {
  .NavigationBar {
    position: fixed;
    height: 80px;
    width: 100%;
    bottom: 0;
    flex-direction: row;
  }
} 