.Contact-form {
  display: flex;
  flex-direction: column;
  animation-duration: 1s;
}

.honeypot {
  display: none;
}

.Contact-form input,
.Contact-form textarea {
  padding: 10px;
  font-size: 1.2em;
  color: #ddd;
  border: none;
  border-bottom: 2px solid crimson;
  border-left: 2px solid crimson;
  background-color: transparent;
  border-radius: 0 0 0 5px;
}


.Contact-form input {
  width: 85%;
  margin-bottom: 20px;
}

.Contact-form textarea {
  height: 150px;
  min-height: 20px;
  max-height: 150px;
  margin-bottom: 20px;
  resize: none;
}

.Contact-form input:focus,
.Contact-form textarea:focus,
.Contact-form button {
  outline: none;
}

.Contact-form button {
  width: 150px;
  padding: 5px;
  font-size: 1.2em;
  background-color: crimson;
  border: 0;
  color: #ddd;
  border-radius: 50px;
  justify-self: flex-end;
  transition: background-color 0.2s;
}

.Contact-form button:hover {
  cursor: pointer;
  background-color: #db143ce6;
}

.twoBtn {
  width: 300px;
  color: #ddd;
  padding: 0 30px;
  display: flex;
  align-items: center;
  position: relative;
  height: 36px;
}

.copyBtn {
  background-color: #7aeec7;
  height: 34px;
  border: none;
  position: absolute;
  right: 0;
  width: 50px;
  border-radius: 15px 0 0 15px;
  cursor: pointer;
  color: #333;
  font-size: 1em;
  transform: rotateY(180deg)
}

@media all and (max-width: 575px) {
  .twoBtn {
    width: 100%;
    margin-bottom: 0;
  }
}