.PopUpProject {
  min-width: 700px;
  max-width: 1200px;
  width:80vw;
  min-height: 480px;
  max-height: 1000px;
  height: 70vh;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #ddd;
  background-color: #2f2f2f;
  box-shadow: 0px 0px 5px 0px #7aeec7;
}

.PopUpProject .title {
  color: #7aeec7;
}

.PopUpProject .stack {
  margin-bottom: 15px;
}

.PopUpProject .description {
  margin-bottom: 20px;
}

.popup-container {
  position: relative;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 100%;
}

.description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.close-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 1.8em;
  color: #7aeec7;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.popup-img {
  justify-self: center;
  max-width: 80%;
  max-height: 80%;
}

.stack {
  display: flex;
  flex-wrap: wrap;
}

.stack .tag {
  background-color: crimson;
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 50px;
  font-size: 0.9em;
}

@media all and (max-width: 575px) {
  .PopUpProject {
    min-width: 300px;
    max-width: 340px;
    width: 70vw;
    min-height: 500px;
    height: auto;
    overflow: scroll;
    top: 10px;
  }

  .popup-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 350px;
  }

  .description-container {
    justify-content: center;
  }

  .popup-img {
    margin-top: 30px;
    width: 100%  
  }

  .stack .tag {
    margin-top: 10px;
  }
}