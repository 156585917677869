.Skills.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  justify-items: center;
}

.Skills .skills-icons {
  height: 17vh;
  color: white;
  animation-duration: 1.2s
}

.skills-tag {
  color: #ddd;
  flex-wrap: wrap;
  display: flex;
}

.skills-tag .tag{
  background-color: crimson;
  padding: 5px 20px;
  margin: 5px;
  border-radius: 50px;
}

@media all and (max-width: 575px) {
  .Skills .skills-icons {
    height: 70px;
  }

  .Skills.grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}