.Title {
  font-size: 2.5em;
  color: #7aeec7;
  font-weight: 700;
  animation-duration: 1s;
}

.line{
  border-bottom: 4px double #7aeec7;
  width: 100px;
  margin-top: 10px;
}

@media all and (max-width: 991px) { 
  .Title {
    font-size: 2em;
  }
} 