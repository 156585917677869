.right-side .profile-image {
  width: 60vh;
  animation-duration: 1.2s;
  align-self: center;
  margin: 10px;
  box-shadow: 2px -2px 2px 0px #7AEEC7;
}

@media all and (max-width: 1199px) {
  .right-side .profile-image {
    width: 50vh;
  }
}

@media all and (max-width: 991px) { 
  .right-side .profile-image {
    width: 40vh;
  }
} 

@media all and (max-width: 768px) {
  .right-side .profile-image {
    width: 40vw;
    border-radius: 150px 0 150px 150px;
    box-shadow: 1px -2px 2px 1px #7AEEC7;
  }
}