.PageView {
  height: 100%;
  margin-right: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.description {
  color: white;
  opacity: 0.8;
  line-height: 24px;
}

.description span {
  color: #7aeec7;
  font-weight: 800;
}

.left-side {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 50px;
}

.right-side {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-right: 50px
}

@media all and (max-width: 768px) {
  .PageView {
    margin-right: 0;
    height: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 120px;
    margin-top: 80px;
  }

  .left-side, 
  .right-side {
    padding: 30px 50px 30px 50px;
  }

  .description {
    text-align: justify;
  }
}
