.Social {
  position: absolute;
  display: flex;
  top: 15px;
  right: 200px;
  font-size: 2em;
  z-index: 2;
}

.Social ion-icon {
  margin: 5px;
  color: white;
  padding: 7px;
  opacity: 0.5;
  transition: opacity 0.2s, color 0.2s;
}

.Social ion-icon:hover {
  color: #7AEEC7;
  opacity: 1;
  cursor: pointer;
}

@media all and (max-width: 768px) {
  .Social {
    right: 35px;
  }
}